<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="2"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12 d-flex justify-content-between">
              <h6>登记的需求</h6>
              <router-link class="text-primary" to="/user/needs/add" target="_blank">
                <img src="@/assets/icon/fbtd.png" style="width: 24px;"/>
                <span class="ml-1">发布需求登记</span>
              </router-link>
            </div>
            <div class="col-12">
              <div class="clearfix mt-4 d-flex align-items-center">
                <div class="float-left">
                  更新时间：
                </div>
                <div class="float-left ml-2">
                  <b-form-input type="date" v-model="para.param.startTime" @click="getList"></b-form-input>
                </div>
                <div class="float-left ml-2 mr-2">
                 至
                </div>
                <div class="float-left">
                  <b-form-input type="date" v-model="para.param.endTime" @click="getList"></b-form-input>
                </div>
                <div class="float-left ml-3">
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(1)" :class="{'active':yues==1}" >近一月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(3)" :class="{'active':yues==3}">近三月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(6)" :class="{'active':yues==6}">近半年</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(12)" :class="{'active':yues==12}">近一年</a>
                </div>
              </div>
              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  流转类型：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <a href="javascript:;" class="mr-3 px-2 py-1"
                       @click="para.param.needsType=='',onScreenClick('needsType','')"
                       :class="{'active': para.param.needsType == ''}">全部</a>
                    <a href="javascript:;"
                       v-for="v in dict.needsType" :key="v.id"
                       @click="onScreenClick('needsType',v.id)"
                       class="mr-3 px-2 py-1"
                       :class="{'active':para.param.needsType==v.id }">{{ v.text }}</a>
                  </div>    
                </div>
              </div>
<!--              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  需求状态：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <a href="javascript:;" class="mr-3 px-2 py-1">全部</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">待审核</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">待交易</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">审核未通过</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">暂停</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">信息被代理</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">已交易</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">已过期</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">预代理</a>
                  </div>

                </div>
              </div>-->
            </div>
            <div class="col-12 mt-4 mb-4">
              <div class="card border-0">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                        <tr>
                          <th class="w-65">需求信息</th>
                          <th>更新时间</th>
                          <th style="width: 80px">操作</th>
                        </tr>
                      </thead>
                      <tbody v-if="list.records && list.records.length > 0">
                        <tr v-for="(m,i) in list.records" :key="m.id">
                          <td>
                            <h6>{{m.title}}</h6>
                            <div class="mt-2 text-gray-777">
                                <span>价格：
                                  <span v-if="m.minPrice > 0 && m.maxPrice > 0" class="text-theme">{{m.minPrice }} ~ {{m.maxPrice}} {{priceUnit(m.priceUnit)}}</span>
									                <span v-else class="text-theme">面议</span>
                                </span>
                                <span v-if="m.years" class="ml-2">年限：{{m.years}}年</span>
                            </div>
                          </td>
                          <td>
                            <div class="mt-1">{{m.createdAt}}</div>
                          </td>
                          <td>
                            <div class="mt-1 mb-1 f-16">
                              <a @click="onDelete(m.id,i)" href="javascript:;" class="ml-2">
                                <b-icon icon="trash"></b-icon>  
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                      <tr>
                        <td colspan="3">
                          <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                            <div class="text-gray-777">没有找到需求信息</div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <div v-if="list && list.total > list.size"
                           class="my-4 d-flex justify-content-center">
                        <b-pagination @change="list" :total-rows="list.total"
                                      :per-page="list.size"></b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      list:[],
      yues:"",
      endTime:'',
      para: {
        param: {
          startTime: "",
          endTime: "",
          needsType:"",
        },
        size: 10,
        current: 1,
      },
    };
  },
  computed:{
    ...mapGetters(["dict"]),
    priceUnit() { 
        return function (value) {
          let priceUnit = '';
          if(value) {
              let a = this.dict.priceUnit.find(x=> x.id == value);
              if(a) {
                  priceUnit = a.text;
              }
          }
          return priceUnit;
        }
      },
  },
  methods: {
    onPageChange(i) {
      this.para.current = i;
      this.getList();
    },
    //获取当前日期几个月内的数据
    createBeginDate(yues) {
      this.yues = yues;
      var date = new Date();
      date.setMonth(date.getMonth() - yues);
      date.toLocaleDateString()
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d

      var dqdate = new Date();
      dqdate.toLocaleDateString()
      var b = dqdate.getFullYear()
      var e = dqdate.getMonth() + 1
      e = e < 10 ? ('0' + e) : e
      var q = dqdate.getDate()
      q = q < 10 ? ('0' + q) : q

      let dqtime = b + '-' + e + '-' + q;
      this.para.param.startTime = time;
      this.para.param.endTime = dqtime;
      this.getList();
    },
    onScreenClick(type, id) {
      this.para.current = 1;
      switch (type) {
        case 'needsType':
          this.para.param.needsType = id;
          break;
      }
      this.getList();
    },
    getList() {
      this.$http.post(this.$api.user.getMyNeeds, this.para).then(res => {
        if(res.code == 1) {
          this.list = res.result;
        }
      })
		},
    onDelete(id, i) {
      this.$dialog.alert({
					title: "温馨提示", 
					message: "是否删除该条需求信息?", 
					showCancelButton: true 
				}).then(() => {
           this.$http.delete(this.$api.user.deleteNeeds + '/' + id).then(res => {
              if(res.code == 1) {
                this.list.splice(i, 1);
              }
          })
				})
    },
  },
  created() {
    this.getList();
  }
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
.form-control {
  height:31px;
}
.active {
  background: #00cc00;
  padding: 5px;
  color: #FFFFFF;
  border-radius: 4px;
}
</style>
